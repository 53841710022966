<script lang="ts" setup>
import type { FetchError } from 'ofetch'

import BasicButton from '~/components/form-elements/BasicButton.vue'
import BasicInput from '~/components/form-elements/BasicInput.vue'
import RadioButtons from '~/components/form-elements/RadioButtons.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import DealerContactPersonPosition from '~/enums/dealer-contact-person-position'
import helpers from '~/helpers'
import ApiErrorHandler from '~/helpers/api-error-handler'
import type { RadioButtonChoice } from '~/types/form-elements'

const model = defineModel<boolean>()

const contactPersonPositionChoices = DealerContactPersonPosition.sortedMembers.map(
  (x: DealerContactPersonPosition): RadioButtonChoice => ({
    labelText: DealerContactPersonPosition.getName(x),
    value: x
  })
)
const buttonTitle = 'Отправить заявку'
const messages = {
  applicationIsCreated: 'Заявка создана.',
  cityFieldCannotBeEmpty: 'Город не введён.',
  emailFieldCannotBeEmpty: 'Email не введён.',
  failedToSendApplication: 'Не удалось отправить заявку.',
  nameFieldCannotBeEmpty: 'Имя не введено.',
  phoneNumberFieldCannotBeEmpty: 'Номер телефона не введён.'
}

const { $apiHelper, $toast } = useNuxtApp()

const form = ref({
  city: null,
  contactPersonEmail: null,
  contactPersonName: null,
  contactPersonPhoneNumber: null,
  contactPersonPositionId: DealerContactPersonPosition.BusinessOwner
})
const isSubmitting = ref(false)

function close (): void {
  model.value = false
}

async function sendApplication (): Promise<void> {
  const { city, contactPersonEmail, contactPersonName, contactPersonPhoneNumber, contactPersonPositionId } = form.value
  const {
    applicationIsCreated,
    cityFieldCannotBeEmpty,
    emailFieldCannotBeEmpty,
    failedToSendApplication,
    nameFieldCannotBeEmpty,
    phoneNumberFieldCannotBeEmpty
  } = messages

  const errors = [
    { condition: !contactPersonName, message: nameFieldCannotBeEmpty },
    { condition: !contactPersonEmail, message: emailFieldCannotBeEmpty },
    { condition: !contactPersonPhoneNumber, message: phoneNumberFieldCannotBeEmpty },
    { condition: !city, message: cityFieldCannotBeEmpty }
  ]

  for (const { condition, message } of errors) {
    if (condition) {
      $toast.error(message)

      return
    }
  }

  const data: any = {
    city,
    contactPersonEmail,
    contactPersonName,
    contactPersonPhoneNumber,
    contactPersonPositionId
  }

  const utmParameters = helpers.getUtmParameters()

  if (utmParameters) {
    data.utmParameters = utmParameters
  }

  isSubmitting.value = true

  try {
    await $apiHelper.dealerApplications.createDealerApplication(data)

    $toast.success(applicationIsCreated)

    close()
  } catch (error) {
    ApiErrorHandler.handleFetchError(error as FetchError, failedToSendApplication)
  }

  isSubmitting.value = false
}
</script>

<template>
  <CustomModalWindow v-model="model">
    <template #title>
      Создание заявки
    </template>

    <BasicInput v-model="form.contactPersonName" class="mb24" is-required label-text="Имя" :max-length="255" />

    <BasicInput v-model="form.contactPersonEmail" class="mb24" is-required label-text="Email" :max-length="255" />

    <BasicInput
      v-model="form.contactPersonPhoneNumber"
      class="mb24"
      is-required
      label-text="Номер телефона"
      :max-length="255"
    />

    <BasicInput v-model="form.city" class="mb24" is-required label-text="Город" :max-length="255" />

    <RadioButtons
      v-model="form.contactPersonPositionId"
      :choices="contactPersonPositionChoices"
      class="mb24"
      is-required
      is-vertical
      label-text="Должность"
      name="contactPersonPositionId"
    />

    <BasicButton color="green" :is-loading="isSubmitting" :title="buttonTitle" @click="sendApplication" />

    <Agreement :button-title="buttonTitle" class="mt24" need-to-agree-with-data-protection-policy />
  </CustomModalWindow>
</template>
